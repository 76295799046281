import React, { useState } from "react";
import { FaPlus } from "react-icons/fa";

const accordionData = [
  {
    title: "How does LocalMan work?",
    content:
      "LocalMan streamlines the ordering process for food businesses. Simply select your items, proceed to checkout, and experience a fast and secure transaction.",
  },
  {
    title: "What payment methods are accepted?",
    content: "Currently, you can only make payments with your bank card.",
  },
  {
    title: "How can I get rewards?",
    content:
      "Once you start making orders you automatically qualify for LocalMan rewards. You can also get rewards when you refer a friend to download and use the mobile app.",
  },
  {
    title: "Is there a transaction fee for using LocalMan?",
    content:
      "You only need your email address and phone number to sign up for a LocalMan account.",
  },
  {
    title: "How can I put my food business on LocalMan?",
    content:
      "You simply have to submit your name and email address on the “Partner with LocalMan” section on our Homepage and Contact us page. Our team will reach out to you as soon as possible!",
  },
];

const LMFAQ = () => {
  const [openIndex, setOpenIndex] = useState(null);

  const handleItemClick = (index: any) => {
    setOpenIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  return (
    <div style={{ marginTop: 40 }}>
      {accordionData.map((item, index) => (
        <div key={index} style={{ borderBottom: "1px solid #8E8A92" }}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              padding: "20px",
              cursor: "pointer",
            }}
            onClick={() => handleItemClick(index)}
          >
            {item.title}
            <span style={{ marginLeft: "auto" }}>
              <FaPlus
                style={{
                  transform: openIndex === index ? "rotate(90deg)" : "none",
                  transition: "transform 0.3s ease",
                }}
              />
            </span>
          </div>
          {openIndex === index && (
            <div style={{ padding: "10px", fontWeight: 400 }}>
              {item.content}
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default LMFAQ;
