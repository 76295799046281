import React from "react";
import Button from "react-bootstrap/Button";
import "../assets/styles/components/button/af_button_style.css";
import { ReactNode } from "react";
import { Spinner } from "react-bootstrap";

type AFButtonProps = {
  handleClick?: any;
  variant?:
    | "primary"
    | "secondary"
    | "success"
    | "danger"
    | "warning"
    | "info"
    | "light"
    | "dark"
    | "link";
  disabled?: boolean;
  name: string | ReactNode;
  type: "submit";
  color: "primary" | "small" | "secondary" | "light" | "danger" | "footer";
  size?: any;
  icon?: any;
  rightIcon?: any;
  className?: string;
  loading?: boolean;
  loadValue?: boolean;
};

let classNameColor = "primary";
let classNameSize = "lg";

const AFButton: React.FC<AFButtonProps> = ({
  handleClick,
  loading,
  loadValue,
  variant,
  disabled,
  type,
  name,
  color,
  size,
  icon,
  rightIcon,
  className,
}) => {
  switch (color) {
    case "primary":
      classNameColor = "af_button_primary";
      break;

    case "secondary":
      classNameColor = "af_button_secondary";
      break;

    case "light":
      classNameColor = "af_button_light";
      break;

    case "footer":
      classNameColor = "af_button_footer";
      break;

    default:
      classNameColor = "af_button_primary";
      break;
  }

  switch (size) {
    case "little":
      classNameSize = "af_button_little";
      break;

    case "sm":
      classNameSize = "af_button_small";
      break;

    case "md":
      classNameSize = "af_button_medium";
      break;

    case "lg":
      classNameSize = "af_button_large";
      break;

    default:
      classNameSize = "af_button_large";
      break;
  }

  return (
    <Button
      // @ts-ignore
      className={[className, classNameColor, classNameSize]}
      type={type}
      variant={variant}
      onClick={handleClick}
      disabled={disabled}
      color={color}
      size={size}
    >
      {icon && <img style={{ width: "22.595px", height: "20.595px", marginRight: 20 }} src={icon} />}

      {!loading ? (
        name
      ) : (
        <Spinner animation="border" variant="light" size="sm" />
      )}

      {rightIcon && <span style={{ marginRight: 20 }}>{rightIcon}</span>}
    </Button>
  );
};

AFButton.defaultProps = {
  loading: false,
};

export default AFButton;
