import React from "react";
import "../assets/styles/pages/af_homepage_style.css";
import "../assets/styles/components/input/af_input_style.css";
import { Navbar, Container, Nav } from "react-bootstrap";
import AFText from "../components/AFText";
import collect from "../assets/images/svg/Collect.svg";
import pay from "../assets/images/svg/Pay.svg";
import Order from "../assets/images/svg/Order.svg";

import mainBanner from "../assets/images/svg/Hero_banner.svg";

import reward from "../assets/images/svg/reward.svg";
import apple from "../assets/images/svg/apple.svg";
import google from "../assets/images/svg/google.svg";

import AFPartner from "../components/AFPartner";
import LMHeader from "../components/LMheader";
import Footer from "../components/LMFooter";

function HomePage() {
  return (
    <div>
      <LMHeader />
      <section className="  first_section_container ">
        <Container>
          <div className="row first_banner_row">
            <div className="col-lg-6 col-sm-12">
              <div id="welcome_to_af_container">
                <AFText
                  text="Say hello to the fastest checkouts ever!!"
                  size="h1"
                  coloredWords={[
                    { word: "checkouts", color: "#F88A32" },
                    { word: "fastest", color: "#F88A32" },
                  ]}
                />
              </div>

              <div id="journey_container">
                <AFText
                  text="Order food with the LocalMan app and checkout in less than 5 minutes at your favourite restaurants."
                  size="light"
                />
              </div>

              <div className="image-container_home">
                <div className="">
                  <img id="apple_image" src={apple} />
                </div>
                <div className="">
                  <img id="google_image" src={google} />
                </div>
              </div>
            </div>

            <div className="images_right_container col-lg-6 col-sm-12">
              <img id="banner_image_left" src={mainBanner} />
            </div>
          </div>
        </Container>
      </section>
      <Container className="quick_step_section">
        <section style={{ textAlign: "center" }}>
          <div className="quick_step_container">
            <div className="">
              <div className="wrap">
                <div id="">
                  <AFText
                    text="Order in 3 Quick Steps from the app"
                    size="h2"
                    coloredWords={[
                      { word: "3", color: "#F88A32" },
                      { word: "Quick", color: "#F88A32" },
                      { word: "Steps", color: "#F88A32" },
                    ]}
                  />
                </div>

                <div id="">
                  <AFText
                    text={`Here’s how to skip the queues and go straight to checkout when you order with LocalMan.`}
                    size="little"
                  />
                </div>
              </div>

              <div className="quick_image_container">
                <div className="row">
                  <div className="col-lg-4 col-md-6 col-sm-12 ">
                    <div className="quick_steps_container">
                      <div>
                        <img id="square_image" src={Order} />
                      </div>

                      <div className="space">
                        <AFText text="SHOP" size="strong" />
                      </div>

                      <div className="space">
                        <AFText
                          text={`Browse meal options from your\n favourite restaurants in-app`}
                          size="little"
                        />
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-4 col-md-6 col-sm-12 ">
                    <div className="quick_steps_container">
                      <div>
                        <img id="square_image" src={pay} />
                      </div>

                      <div className="space">
                        <AFText text="PAY" size="strong" />
                      </div>

                      <div className="space">
                        <AFText
                          text={`Make your secure payment to\n checkout via the app`}
                          size="little"
                        />
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-4 col-md-6  col-sm-12 ">
                    <div className="quick_steps_container">
                      <div>
                        <img id="square_image" src={collect} />
                      </div>

                      <div className="space">
                        <AFText text="COLLECT" size="strong" />
                      </div>

                      <div className="space">
                        <AFText
                          text={`Walk in to the restaurant outlet &\n collect your food right away!`}
                          size="little"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section>
          <div
            style={{ marginTop: 60 }}
            className="row first_banner_row amazing_first_banner_row"
          >
            <div className="images_right_container col-lg-6 col-sm-12">
              <img id="amazing_rewards_image" src={reward} />
            </div>

            <div className="col-lg-6 col-sm-12" style={{ marginTop: 40 }}>
              <div id="welcome_to_af_container">
                <AFText text="Get amazing rewards!" size="h1" color="white" />
              </div>

              <div id="journey_container">
                <AFText
                  text="Get special rewards like free meals and airtime when you order food from Localman! "
                  size="light"
                  color="white"
                />
              </div>

              <div className="image-container_home">
                <div className="">
                  <img id="apple_image" src={apple} />
                </div>
                <div className="">
                  <img id="google_image" src={google} />
                </div>
              </div>
            </div>
          </div>
        </section>

        <section style={{ marginTop: 40 }}>
          <AFPartner />
        </section>
      </Container>
      <section>
        <Footer />
      </section>
    </div>
  );
}

export default HomePage;
