export const EMAIL_INPUT_VALIDATION = {
    required: 'Email is required',
  
    pattern: {
      value: /[a-z0-9]+@[a-z]+\.[a-z]{2,3}/,
      message: 'This is not a valid email',
    },
  };
  
  export const URL_INPUT_VALIDATION = {
    required: 'Enter a link ',
    pattern: {
      value:
        /^(?!\s*$)(?:https?:\/\/|www\.|ftp:\/\/)(?:\S+@\S+\.\S+|\S+(?::\S*)?)(?:\/[\w#!:.?+=&%@!\-\/]*)?$/,
      message: 'This is not a valid link',
    },
  };
  
  export const MINIMUM_NIPS_VALIDATIONS = {
    required: 'Enter your nips to bids ',
    min: {
      value: 5,
      message: 'Please you can only bid between the range of 5 NIPS and above.',
    },
  };
  
  export const PASSWORD_INPUT_VALIDATION = {
    required: 'Password is required',
    minLength: {
      value: 8,
      message: 'At least eight characters, combination of letters and numbers',
    },
  };
  
  export const WORD_INPUT_VALIDATION = {
    required: 'This field is required',
    minLength: {
      value: 3,
      message: 'At least three characters, combination of letters and numbers',
    },
  };
  
  export const REGULAR_INPUT_VALIDATION = {
    required: 'This field is required',
  };
  
  export const NUMBER_INPUT_VALIDATION = {
    required: 'This field is required',
    pattern: {
      value: /^[0-9]/,
      message: 'Amount can only contain numbers',
    },
  };
  
  export const PHONE_NUMBER_INPUT_VALIDATION = {
    required: 'Phone number is required',
    minLength: {
      value: 10,
      message: 'Must be eleven digits',
    },
    maxLength: {
      value: 18,
      message: 'Must be eleven digits',
    },
    pattern: {
      value: /^[0-9]/,
      message: 'Phone number can only contain numbers',
    },
  };
  
  export const WORD_INPUT_VALIDATION_OPTIONAL = {
    minLength: {
      value: 3,
      message: 'At least three characters, combination of letters and numbers',
    },
  };
  
  export const SELECT_INPUT_VALIDATION = {
    required: 'This field is required',
  };
  
  export const AGREEMENT_VALIDATION = {
    required: 'Agree to our terms and conditions',
  };
  
  export const MULTISELECT_INPUT_VALIDATION = {
    required: 'this field is required',
  };
  