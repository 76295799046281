import React from "react";
import "../assets/styles/pages/af_homepage_style.css";
import "../assets/styles/components/input/af_input_style.css";

import { Navbar, Container, Nav } from "react-bootstrap";
import AFText from "../components/AFText";

import LMHeader from "../components/LMheader";
import Footer from "../components/LMFooter";
import LMFAQ from "../components/LMFAQ";
import leafLeft from "../assets/images/svg/left.svg";
import leafRight from "../assets/images/svg/right.svg";

function FAQ() {

  return (
    <div>
      <LMHeader />
      <section className="  first_section_container" style={{height: "100%"}}>
        <img
          style={{
            width: 295,
            height: 322.06,
            left: -51,
            top: 10,
            position: "absolute",
            transform: "rotate(-8.43deg)",
            transformOrigin: "0 0",
          }}
          src={leafLeft}
        />
        <img
          style={{
            width: 264.97,
            height: 421,
            right: 0,
            top: 50,
            position: "absolute",
          }}
          src={leafRight}
        />
        <Container>
          <div className="row first_banner_row" style={{ textAlign: "center" }}>
            <div id="welcome_to_af_container">
              <AFText text="Privacy Policy" size="h1" />
            </div>

            <div id="">
              <AFText
                text="Effective Date: 6th January 2024"
                size="light"
                coloredWords={[
                  { word: "send", color: "#F88A32" },
                  { word: "us", color: "#F88A32" },
                  { word: "a", color: "#F88A32" },
                  { word: "message.", color: "#F88A32" },
                ]}
              />
            </div>
          </div>
        </Container>
      </section>
      <Container>
        <section
          style={{ background: "#fff" }}
          className="second_section_container "
        >
          <div className="col-12 mx-auto">
              <div className="content">
                <div className="row">
                  <div className="col-12 col-lg-9 mx-auto">
                    <p>
                      We're here to provide you with tools and services that are
                      designed to help you make financial progress. Before you
                      get started, we'd like to tell you more about what we do
                      with information we collect about you.
                    </p>
                    <p>
                      When you use, access or interact with our Services, you
                      are agreeing to our Terms of Service and consenting to how
                      we collect, share and otherwise use information about you
                      as described in this Privacy Policy.
                    </p>
                    <p>
                      You also consent to receive notifications concerning your
                      financial privacy from us electronically. Since we do
                      collect information about you, you should also know we're
                      committed to securing our systems and the protection of
                      your data.
                    </p>
                    <h5>What This Policy Covers</h5>
                    <p>
                      As a financial services company, we’re providing this
                      Privacy Policy to give you notice about how Localman Tech
                      and its affiliated companies (collectively, "Localman
                      Tech", "we", "us" or "our") collect, use, and share
                      information in connection with your access and use of our
                      Services. It does not cover the privacy practices of third
                      parties that we do not own or control.{" "}
                    </p>

                    <p>
                      That said you should know that we may provide third party
                      content or links to third-party web sites or applications,
                      or include features that allow you to connect your
                      Localman Tech account with your accounts with third
                      parties, such as financial institutions or social media
                      platforms like Facebook. When you leave our Services or
                      interact with a third-party feature, you should also read
                      the applicable third party's privacy policies and terms of
                      service to make sure you understand how they might collect
                      and share information about you (everybody or every
                      company is different).
                    </p>

                    <h5>What Information We Collect </h5>
                    <p>
                      To put it simply, there are three main ways we collect
                      information about you: when you give it to us, with
                      automatic technologies and when we ask others for it.
                      Allow us to explain.
                    </p>
                    <h5>Information You Provide to Us</h5>
                    <p>
                      You may provide information to us in various ways, such as
                      through forms, features or other means offered through our
                      Services. This includes information you provide to us
                      about other individuals, such as the maiden name of your
                      mother, even if they are not Localman Tech members. A
                      couple of examples include information you provide to us
                      when registering as a member, and feedback forms where you
                      send us comments, questions, or other feedback. The types
                      of information you provide us fits into two general
                      categories:
                    </p>

                    <ul>
                      <li>
                        <b>Personal Information:</b> This is information that
                        identifies you or others personally, such as your name,
                        address, email address, Bank Verification Number (BVN),
                        KYC (Know Your Customer) details and phone number.
                      </li>
                      <li>
                        <b>Non-Personal Information:</b> This is information
                        about you or your household that doesn't identify you
                        personally, like your gender, income or what types of
                        financial products you're interested in.
                      </li>
                    </ul>
                    <p>
                      When we combine information that isn't Personal
                      Information (like Non-Personal Information or Usage
                      Information, which we explain below) with Personal
                      Information, we treat it like Personal Information for as
                      long as it is combined.
                    </p>

                    <h5>Information We Automatically Collect</h5>
                    <p>
                      In addition to information you provide to us, we
                      automatically collect certain information about how you
                      access and interact with our Services (“Usage
                      Information”). This Usage Information is a key part of how
                      we improve your Localman Tech experience and provide you
                      with more personalized insights and recommendations. We,
                      our partners and affiliates may use various technologies
                      to collect and store Usage Information in connection with
                      our Services. These may include things like cookies,
                      browser, web storage (e.g. HTML5) and similar
                      technologies. There are three types of Usage Information
                      we may collect:
                    </p>
                    <ul>
                      <li>
                        <b>Device Information:</b> This is information about how
                        you access our Services, such as the operating system,
                        hardware model, application or browser type and version,
                        and unique identifiers associated with your device. We
                        may also integrate with built-in device features (e.g.,
                        when you turn on fingerprint access for your Localman
                        Tech account).
                      </li>
                      <li>
                        <b>Log Information:</b> There are certain pieces of
                        information we automatically collect in system logs or
                        similar files about how you interact with our Services,
                        such as which features you visit or click on. This Log
                        Information may also include things like your IP
                        address, browser or device configuration, date and time
                        of access and cookie information.
                      </li>
                      <li>
                        <b>Location Information:</b> We may use certain
                        information like your IP address or other Device
                        Information or Log Information to estimate your location
                        (e.g., your city or state). We may also request to use
                        location-enabled services on your device (which
                        typically provide GPS or Wi-Fi access point details) to
                        enhance our Services. We will only use these
                        location-enabled services on your device with your
                        consent, though.
                      </li>
                    </ul>
                    <h5>Information Third Parties Provide About You</h5>
                    <p>
                      Many of our features rely on information about you that we
                      collect from third parties, such as the financial
                      information from banks; credit & loan reports we request
                      on your behalf from other financial institutions like Ren
                      Money, Creditville and others. We may also work with
                      different third party partners to supplement the Usage
                      Information and other information we collect directly from
                      you, including but not limited to information from
                      facebook, google, twitter and others. For example, our
                      partners may provide us with information such as
                      employment or income data, vehicle or driver information,
                      or information about special offers available based on
                      your credit & loan profile so that we can give you more
                      personalized recommendations. We might also collect
                      information from other users like you through things like
                      a member referral program.
                    </p>
                    <h5>How We Use the Information We Collect</h5>
                    <p>
                      We use the information we collect from you and third
                      parties to operate our Services, communicate with you,
                      conduct research and development to improve or enhance our
                      Services and our users' experience, and promote our
                      Services. These are a few examples:
                    </p>
                    <ul>
                      <li>
                        <b>
                          To create your account and provide the Services to
                          you.
                        </b>
                        We use the information we collect to create and secure
                        your account, create and maintain your Member Profile,
                        and to provide the Services to you.
                      </li>
                      <li>
                        <b>
                          To provide features to you, such as your free credit &
                          loan reports.
                        </b>{" "}
                        We use the information we collect to provide you with
                        free credit & loan reports from our financial partners.
                        Localman Tech offers much more than free credit & loan
                        reports though; the information we collect also goes
                        into developing and bringing you various other features
                        like free credit monitoring, ID monitoring, tracking
                        your spending, and personalized offer recommendations.
                      </li>
                      <li>
                        <b>To improve our recommendations and insights.</b> The
                        information we collect also helps us tailor our content
                        and improve the way we match you to recommended offers
                        or insights. We're constantly working on ways to better
                        identify and present the information that's most
                        relevant to you.
                      </li>
                      <li>
                        <b>To communicate with you.</b> We use information we
                        collect to provide you with member support or to send
                        you things like service-related announcements, credit or
                        ID monitoring alerts, recommendations, offers from us,
                        our partners, our affiliates and other updates or
                        promotions. Most of the time, we'll communicate with you
                        by email, but we may also send you push notifications,
                        browser notifications, or even mail via courier.
                      </li>
                      <li>
                        <b>
                          To conduct research and to improve and promote our
                          services.
                        </b>
                        We use the information we collect to conduct research
                        and to improve or enhance and promote our Services
                      </li>
                      <li>
                        <b>For legal reasons.</b> We reserve the right to
                        access, use, preserve, transfer or disclose, at any time
                        without notice to you, any information (including
                        Personal Information) as reasonably necessary to:
                        <ul>
                          <li>
                            Comply with any applicable law, regulation, subpoena
                            or legal process, or respond to any governmental
                            requests and cooperate with law enforcement, if we
                            believe such action is required or permitted by law;
                            and
                          </li>
                          <li>
                            Enforce our Terms of Service, including
                            investigating any potential violations;
                          </li>
                        </ul>
                      </li>
                      <li>
                        <b>To prevent or detect fraud.</b>
                        We use the information to protect the safety, integrity,
                        rights or security of our members, our Services or
                        equipment, or any third party; detect, prevent or
                        otherwise address fraud, security or technical issues
                        related to our Services or our partners; or in required
                        institutional risk control programs.
                      </li>
                    </ul>
                    <h5>When We Share the Information We Collect</h5>
                    <p>
                      First, we want to be clear about how we do not share
                      information about you (what we call the “No-Nos”):
                    </p>
                    <ul>
                      <li>
                        We do not share your financial information and credit &
                        loan reports with unaffiliated third parties (except as
                        required by law, or agents acting on our behalf; we
                        explain that below).
                      </li>
                      <li>
                        We do not sell your Personal Information to or share
                        your Personal Information with unaffiliated third
                        parties for their own advertising or marketing purposes
                      </li>
                    </ul>
                    <p>
                      Now that we've covered the No-Nos, let us explain how we
                      could share with third parties. We may share information
                      about you, including Personal Information, with third
                      parties in a variety of ways, such as
                    </p>
                    <ul>
                      <li>
                        <b>Agents acting on our behalf.</b> We may share
                        information about you with service providers or agents
                        in order to have them perform on our behalf, any of the
                        activities we are permitted to conduct under this
                        Privacy Policy and our Terms of Service. This includes
                        things like designing or operating features, helping us
                        research and test feature improvements, identifying
                        offers or personalized recommendations, performing
                        analytics and marketing our Services. These third
                        parties are acting on our behalf so while they may have
                        access to your information, including things like your
                        credit and loan reports, they're required to follow our
                        instructions and meet the standards explained in our
                        Privacy Policy when handling information about you that
                        we provide them.
                      </li>
                      <li>
                        <b>
                          When we collaborate with third parties to provide and
                          improve our Services.
                        </b>
                        We may sometimes share information about you with third
                        parties we collaborate with in connection with providing
                        or improving our Services. This can occur, for example,
                        when we send your information to a financial institution
                        to retrieve your credit & loan report. Other examples
                        include when we're doing a joint research project with
                        another organization to help improve our Services. We
                        may also share information like the feedback you submit
                        to us if it relates to both our Services and a partner's
                        products. When we share information with third-party
                        collaborators, our collaborators are obligated to follow
                        the No-Nos above, but may also use the information in
                        connection with their own activities to the extent
                        allowed under their own privacy practices (e.g., respond
                        to your feedback, conduct additional research, or
                        improve a feature of theirs).
                      </li>
                      <li>
                        <b>Our partners when you ask for their services.</b> We
                        may also give you opportunities to interact with third
                        party partners through our Services, such as when we
                        provide you with the opportunity to review and send your
                        personal information to a partner in order to
                        pre-populate your application on the partner’s site,
                        when we send your personal information to partners in
                        order to generate offers for you to review, when we send
                        your application information directly to our partners,
                        or when we send you to the partner’s site for you to
                        provide the information directly to them. In some cases,
                        if you click through to go to a partner’s site, you will
                        automatically be sending your personal information to
                        that partner. When this happens, you will still have to
                        submit your application on the partner’s site. Remember
                        that any information you provide to the partner, whether
                        through us or on your own, will be subject to their
                        privacy practices and terms and conditions.
                      </li>
                      <li>
                        <b>When you publicly post the information.</b> As part
                        of our Services, we may provide opportunities for you to
                        publicly post reviews, questions, comments, suggestions
                        or other content, which may include Personal Information
                        and portions of your Member Profile designated as public
                        profile information, like your name or user name.
                        Anything you share in a public forum is, obviously,
                        public, so think carefully about what you decide to
                        share.
                      </li>
                      <li>
                        <b>As Part of a Corporate Change.</b> We may disclose
                        and transfer information about you to a third party as
                        part of, or in preparation for, a change of control,
                        restructuring, corporate change, or sale or transfer of
                        assets. If such a business transfer results in a
                        material change in the treatment of your Personal
                        Information, you will be notified by e-mail (using the
                        primary email address on your account) or by a prominent
                        notice on our site.
                      </li>
                      <li>
                        <b>For Legal Reasons.</b> We reserve the right to
                        transfer or disclose information about you to third
                        parties for certain legal purposes, and as permitted by
                        law.
                      </li>
                      <li>
                        <b>To Prevent Fraud.</b> We reserve the right to
                        transfer or disclose information about you to third
                        parties to protect against or prevent actual or
                        potential fraud, unauthorized transactions, claims, or
                        other liability, and for required institutional risk
                        controls.
                      </li>
                      <li>
                        <b>With Your Consent.</b> There may also be other
                        limited contexts in which we share specific types of
                        personal information with your express consent.
                      </li>
                    </ul>
                    <p>
                      We may also share with third parties de-identified or
                      aggregated data we collect, such as de-identified or
                      aggregated financial information and credit & loan
                      reports, de-identified demographic information, or
                      information about the computer or device from which you
                      access the Service. We share such information for a
                      variety of purposes, including to analyze Service usage,
                      develop shared services, and improve our own products and
                      features.
                    </p>

                    <h5>
                      How to Access, Update or Deactivate Your Account Accessing
                      and Updating Your Account Information
                    </h5>

                    <p>
                      You are responsible for maintaining the accuracy of the
                      information you provide to us, such as your Member Profile
                      information. You can access and make changes to certain
                      Personal Information by logging in to your account through
                      our website and updating your "Personal Information" page.
                    </p>

                    <h5>Communications Preferences</h5>
                    <p>
                      You can manage your communications preferences by visiting
                      your account profile. For example, you can choose which
                      types of monitoring alerts you want to receive. We'll also
                      include "unsubscribe" instructions in marketing emails.
                      You may also have the ability to adjust app- or
                      browser-specific communications settings (e.g., for things
                      like push or browser notifications) within the settings
                      screen of your mobile app or browser. Technology moves
                      fast, so the types of communications and communication
                      preference options might change from time to time.
                    </p>

                    <h5>
                      Canceling Your Membership and Deactivating Your Account
                    </h5>

                    <p>
                      We hope this never happens, but if you decide you no
                      longer want to use our Services, you can cancel and
                      deactivate your account at any time.
                    </p>

                    <p>
                      One more thing to note: Due to our recordkeeping and
                      information retention requirements, we do not delete
                      information about you upon deactivation. We will, however,
                      disable your account and stop sending you further
                      communications.
                    </p>

                    <h5>
                      Our Interest-Based Advertisement Practices Advertising
                      Service Providers
                    </h5>

                    <p>
                      Some of our third-party service providers or partners are
                      advertising networks, marketing partners, publishers and
                      other entities who perform analytics and advertise our
                      products and services on websites not affiliated with us
                      (“Advertising Service Providers”). In connection with
                      these advertising services, we or our Advertising Service
                      Providers, like Google Analytics may use cookies, web
                      beacons, and similar technologies to collect behavioral
                      information about how you use our site or other websites
                      in order to perform tracking and marketing analytics or
                      serve advertisements that are more likely to be of
                      interest to you ("Interest-Based Advertisements"). Our
                      Advertising Service Providers may permit you to opt out of
                      all or some Interest-Based Advertisements on designated
                      websites.
                    </p>

                    <h5>Do Not Track Signals</h5>
                    <p>
                      Please note that blocking cookies may affect your browsing
                      experience on many sites and may limit your ability to use
                      certain features of our Services, including many of the
                      personalized features we provide for our members.
                    </p>

                    <h5>
                      Our Security Practices and Social Security Protection
                      Policy Statement
                    </h5>

                    <p>
                      Localman Tech maintains physical, electronic,
                      administrative and procedural safeguards intended to
                      protect the confidentiality of Personal and financial
                      Information that we collect about you, including Bank
                      Verification Number (BVN). We limit access to BVN in order
                      to help protect against their loss or misuse and we do not
                      disclose BVN to third parties except with your consent, or
                      where such disclosure is required or permitted by law.
                    </p>

                    <p>
                      If you have any questions about security on our Services,
                      you can contact us at{" "}
                      <a href="mailto:support@localman.co">
                        support@localman.co
                      </a>
                    </p>

                    <h5>Changes to Our Privacy Policy</h5>
                    <p>
                      We reserve the right to change this Privacy Policy at any
                      time without notice to you. If there are any material
                      changes in the way we treat your financial information,
                      credit & loan report information or Personal Information,
                      Localman Tech will send you a notice before the change
                      goes into effect. When you register and agree to our Terms
                      of Service and Privacy Policy, you are consenting to
                      receive communications from us, including communications
                      about our privacy policies and practices, electronically.
                      Localman Tech will also promptly make an updated Privacy
                      Policy publicly available on our platform. Changes will be
                      effective as soon as the revised Privacy Policy is posted
                      unless otherwise stated.
                    </p>

                    <h5>Want More Information?</h5>
                    <p>
                      Thanks for taking the time to read through all this. If
                      you have questions regarding this policy or privacy
                      practices at Localman Tech, you can contact our legal
                      department at{" "}
                      <a href="mailto:legal@localman.co">legal@localman.co</a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
        </section>
      <section>
        <Footer />
      </section>
      </Container>
    </div>
  );
}

export default FAQ;
