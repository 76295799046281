import React from "react";
import Routes from "./routes";
import 'bootstrap/dist/css/bootstrap.min.css';
import "./assets/styles/style_config.css";
import "./assets/styles/styles.css";


function App() {
  return (
    <div className="app_container">
      <Routes />
    </div>
  );
}
export default App;
