import { ChangeEvent, FocusEvent, MouseEvent } from "react";
import { Form, InputGroup, FormControl } from "react-bootstrap";

import "../assets/styles/components/input/af_input_style.css";

interface InputFieldProps {
  label?: string;
  placeholder?: string;
  type: string;
  icon?: any;
  value?: string;
  error?: any;
  onBlur?: (event: FocusEvent<HTMLInputElement>) => void;
  onChangeText?: any;
  onClick?: (event: MouseEvent<HTMLButtonElement>) => void;
  maxLength?: number;
  editable?: boolean;
  labelRight?: string;
  rightLabelLink?: string;
  handleIconAction?: any;
  rows?: number;
  hasLabel?: boolean;
  min?: number;
  max?: number;
  id?: string;
  background?: string;
}

const LMInput = ({
  label,
  rows,
  placeholder,
  type,
  icon,
  value,
  error,
  onBlur,
  onChangeText,
  id,
  onClick,
  maxLength,
  editable,
  labelRight,
  rightLabelLink,
  hasLabel,
  handleIconAction,
  min,
  max,
  background,
}: InputFieldProps) => {
  const renderTextArea = () => (
    <>
      <FormControl
        as="textarea"
        rows={rows}
        className={
          error
            ? "text_input_danger nt_textarea"
            : "text_input_style2 nt_textarea"
        }
        placeholder={placeholder}
        type={type}
        value={value}
        maxLength={maxLength}
        onBlur={onBlur}
        onChange={onChangeText}
        disabled={editable}
      />
    </>
  );

  const renderInput2 = () => (
    <>
      <input
        className={
          error
            ? "text_input_danger"
            : "text_input_style2" && icon
            ? "nt_input_icon text_input_style2"
            : "text_input_style2"
        }
        placeholder={placeholder}
        type={type}
        value={value}
        maxLength={maxLength}
        onBlur={onBlur}
        onChange={onChangeText}
        disabled={editable}
        min={min}
        max={max}
        id={id}
      />
    </>
  );

  return (
    <div>
      {hasLabel && (
        <label style={{ fontSize: 19 }} className="mb-2 " id="label">
          {label}
        </label>
      )}
      {labelRight && (
        <a href={rightLabelLink}>
          <label
            style={{ float: "right", cursor: "pointer" }}
            className="nt_link"
          >
            {labelRight}
          </label>
        </a>
      )}
      <Form className="search  mb-3">
        <span onClick={handleIconAction} style={{ cursor: "pointer" }}></span>
        {type === "textarea" ? renderTextArea() : renderInput2()}
      </Form>
      {error && <p className="text-danger">{error}</p>}
    </div>
  );
};

LMInput.defaultProps = {
  type: "text",
  editable: false,
  hasLabel: true,
};

export default LMInput;
