import React from "react";
import "../assets/styles/pages/af_homepage_style.css";
import "../assets/styles/components/input/af_input_style.css";
import { Navbar, Container, Nav } from "react-bootstrap";
import AFText from "../components/AFText";
import collect from "../assets/images/svg/Reward2.svg";
import pay from "../assets/images/svg/checkout.svg";
import Order from "../assets/images/svg/userfriendly.svg";

import image63 from "../assets/images/svg/image63.png";
import image66 from "../assets/images/svg/image66.png";
import image68 from "../assets/images/svg/image68.png";
import image69 from "../assets/images/svg/image69.png";
import image70 from "../assets/images/svg/image70.png";
import mainBanner from "../assets/images/svg/Hero_banner.svg";

import reward from "../assets/images/svg/reward.svg";
import apple from "../assets/images/svg/apple.svg";
import google from "../assets/images/svg/google.svg";

import AFPartner from "../components/AFPartner";
import LMHeader from "../components/LMheader";
import Footer from "../components/LMFooter";
import { FaImages } from "react-icons/fa";

function About() {
  const logos: any = [image63, image66, image68, image69, image70, image63];
  return (
    <div>
      <LMHeader />
      <section className="first_section_container">
        <Container>
          <div className="row first_banner_row">
            <div className="col-lg-7 col-sm-12">
              <div id="welcome_to_af_container">
                <AFText
                  text="We provide the fastest checkout services for food businesses!!"
                  size="h1"
                  coloredWords={[
                    { word: "checkout", color: "#F88A32" },
                    { word: "fastest", color: "#F88A32" },
                  ]}
                />
              </div>

              <div id="journey_container">
                <AFText
                  text="LocalMan is on a mission to revolutionize the checkout experience in the food business. We understand that in today's fast-paced world, time is of the essence, especially when it comes to serving customers. Which is why we've crafted an unparalleled checkout experience that is swift and as satisfying as enjoying your favourite meal. "
                  size="light"
                />
              </div>

              <div className="image-container_home">
                <div className="">
                  <img id="apple_image" src={apple} />
                </div>
                <div className="">
                  <img id="google_image" src={google} />
                </div>
              </div>
            </div>

            <div className="images_right_container col-lg-5 col-sm-12">
              <img
                id="banner_image_left"
                src={mainBanner}
               
              />
            </div>
          </div>
        </Container>
      </section>
      <Container className="quick_step_section ">
        <section style={{ textAlign: "center" }}>
          <div className="quick_step_container">
            <div className="">
              <div id="">
                <AFText text="What Sets Us Apart" size="h2" />
              </div>

              <div className="quick_image_container">
                <div className="row">
                  <div className="col-lg-4 col-sm-12 quick_col">
                    <div>
                      <img
                        id="square_image"
                        style={{ height: 150, width: 250 }}
                        src={Order}
                      />
                    </div>

                    <div className="space">
                      <AFText text="User-Friendly Interface" size="strong" />
                    </div>

                    <div className="space">
                      <AFText
                        text={`Our intuitive platform makes the checkout process easy to navigate, making it a breeze to place and pay for orders.`}
                        size="little"
                      />
                    </div>
                  </div>

                  <div className="col-lg-4 col-sm-12 quick_col">
                    <div>
                      <img
                        id="square_image"
                        style={{ height: 150, width: 250 }}
                        src={pay}
                      />
                    </div>

                    <div className="space">
                      <AFText text="Secure 5 Minutes Checkout" size="strong" />
                    </div>

                    <div className="space">
                      <AFText
                        text={`Your checkout is processed securely at lightning speed, so you can enjoy your delicious meal without worry or delay.`}
                        size="little"
                      />
                    </div>
                  </div>

                  <div className="col-lg-4 col-sm-12 quick_col">
                    <div>
                      <img
                        id="square_image"
                        style={{ height: 150, width: 250 }}
                        src={collect}
                      />
                    </div>

                    <div className="space">
                      <AFText text="Amazing Rewards" size="strong" />
                    </div>

                    <div className="space">
                      <AFText
                        text={`We value our customers and ever so often, we reward their loyalty with free meals and airtime gifts.`}
                        size="little"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Container>
      <Container >
        <section
          style={{ textAlign: "center", background: "#fff" }}
          className="second_section_container "
        >
          <div id="">
            <AFText text="Our Amazing Partners" size="h2" />
          </div>

          <div className="image-container">
            {logos.map((image: string, index: number) => (
              <img
                className="amazing_images"
                src={image}
                key={index}
                alt="logo"
              />
            ))}
          </div>
        </section>
      </Container>
      <section>
        <Footer />
      </section>
    </div>
  );
}

export default About;
