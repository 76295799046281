import React from "react";
import { ReactNode } from "react";
import "../assets/styles/components/text/af_text_style.css";

interface ColoredWord {
  word: string;
  color: string;
}

interface AFTextProps {
  size: "h1" | "h2" | "h3" | "h4" | "h5" | "p" | "strong" | "light" | "little";
  text: string | ReactNode;
  className?: any;
  link?: boolean;
  linkValue?: string;
  linkHref?: string;
  color?: any;
  icon?: any;
  handleClick?: any;
  coloredWords?: ColoredWord[];
}

const AFText: React.FC<AFTextProps> = ({
  size,
  text,
  className,
  link,
  linkValue,
  linkHref,
  color,
  icon,
  handleClick,
  coloredWords,
}: AFTextProps) => {
  switch (size) {
    case "h1":
      className = "af_text_h1";
      break;
    case "h2":
      className = "af_text_h2";
      break;
    case "h3":
      className = "af_text_h3";
      break;
    case "h4":
      className = "af_text_h4";
      break;
    case "h5":
      className = "af_text_h5";
      break;
    case "p":
      className = "af_text_p";
      break;
    case "strong":
      className = "af_text_strong";
      break;
    case "light":
      className = "af_text_light";
      break;
    case "little":
      className = "af_text_little";
      break;
    default:
      className = "af_text_primary";
      break;
  }

  const dynamicStyle: Record<string, any> = {};

  switch (color) {
    case "primary":
      className += " af_text_primary";
      break;
      case "white":
        className += " af_text_white";
        break;
 
    default:
      className += " af_text_primary";
      break;
  }

  const textLines = typeof text === "string" ? text.split("\n") : [text];

  return (
    <span onClick={handleClick} className={handleClick ? "af_edit_btn" : ""}>
      <p className={className}>
        {icon && (
          <img
            style={{ width: "35px", height: "35px" }}
            src={icon}
            alt="Icon"
          />
        )}
        <div
          style={{
            ...dynamicStyle,
            ...(icon && {
              marginTop: -26,
              marginLeft: 40,
              lineHeight: "15.954px",
            }),
          }}
        >
          {textLines.map((line, index) => (
            <React.Fragment key={index}>
              {//@ts-ignore
              coloredWords ? line.split(" ").map((word, wordIndex) => {
                    const matchingWord = coloredWords.find(
                      (coloredWord) =>
                        coloredWord.word.toLowerCase() === word.toLowerCase()
                    );

                    return matchingWord ? (
                      <span
                        key={wordIndex}
                        style={{ color: matchingWord.color }}
                        className="colored_text"
                      >
                        {word}&nbsp;
                      </span>
                    ) : (
                      word + " "
                    );
                  })
                : line}
              {index < textLines.length - 1 && <br />}
            </React.Fragment>
          ))}
        </div>
        {link && (
          <a href={linkHref} className="af_link">
            {linkValue}
          </a>
        )}
      </p>
    </span>
  );
};

AFText.defaultProps = {
  text: "Null",
  link: false,
};

export default AFText;
