import React, { useState } from "react";
import "../assets/styles/pages/af_homepage_style.css";
import "../assets/styles/components/input/af_input_style.css";
import { Navbar, Container, Nav } from "react-bootstrap";
import AFText from "../components/AFText";

import AFPartner from "../components/AFPartner";
import LMHeader from "../components/LMheader";
import Footer from "../components/LMFooter";
import LMFAQ from "../components/LMFAQ";
import LMInput from "../components/LMInput";
import leafLeft from "../assets/images/svg/left.svg";
import leafRight from "../assets/images/svg/right.svg";
import { Controller, useForm } from "react-hook-form";
import {
  EMAIL_INPUT_VALIDATION,
  SELECT_INPUT_VALIDATION,
  WORD_INPUT_VALIDATION,
} from "../utils/helper/validation";
import AFButton from "../components/AFButton";
import AFSelect from "../components/AFSelect";
function Contact() {
  const [loading, setLoading] = useState<boolean>(false);
  let loginInitalValues: any = {
    email: "",
    fullname: "",
    category: "",
    message: "",
  };
  const {
    control,
    getValues,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: loginInitalValues,
  });
  const onSubmit = (data: any) => {
    console.log(data);
  };

  const category = [
    { label: "General inquiry", value: "General inquiry" },
    { label: "Payment issues", value: "Payment issues" },
    { label: "Order-related inquiry", value: "Order-related inquiry" },
    { label: "Other", value: "Other" },
  ];

  const faqData = [
    {
      id: 1,
      title: "How does LocalMan work?",
      content:
        "LocalMan streamlines the ordering process for food businesses. Simply select your items, proceed to checkout, and experience a fast and secure transaction.",
    },
    {
      id: 2,
      title: "What payment methods are accepted?",
      content:
        "Check out membership page, choose the membership package that is ideal for you. We recommend packages of 1 year validity atleast to get the maximum out of website.",
    },
    {
      id: 3,
      title: "How can I get rewards?",
      content:
        "Check out membership page, choose the membership package that is ideal for you. We recommend packages of 1 year validity atleast to get the maximum out of website.",
    },
    {
      id: 4,
      title: "Is there a transaction fee for using LocalMan?",
      content:
        "Check out membership page, choose the membership package that is ideal for you. We recommend packages of 1 year validity atleast to get the maximum out of website.",
    },
  ];

  return (
    <div>
      <LMHeader />
      <section className="  first_section_container" style={{height: "100%"}}>
        <Container>
          <img
            style={{
              width: 295,
              height: 322.06,
              left: -51,
              top: 600,
              position: "absolute",
              transform: "rotate(-8.43deg)",
              transformOrigin: "0 0",
            }}
            src={leafLeft}
          />
          <img
            style={{
              width: 164.97,
              height: 421,
              right: 0,
              top: 0,
              position: "absolute",
            }}
            src={leafRight}
          />
          <div className="row first_banner_row ">
            <div className="col-lg-6 col-sm-12 contact_col" style={{ paddingRight: 30 , marginTop: "-20px"}}>
              <div id="welcome_to_af_container">
                <AFText text="Hi, we’d love to hear from you!" size="h1" />
              </div>

              <div id="journey_container">
                <AFText
                  text="If you were unable to find answers to your questions from our FAQ page, you can send us a message and our team would get back to you as soon as possible."
                  size="light"
                
                />
              </div>

              <div>
                <AFText text="Popular Questions" size="strong" />
              </div>

              <div>
                <LMFAQ />
              </div>
            </div>

            <div className=" col-lg-6 col-sm-12 contact_col" >
              <div className="row contact_right_container">
                <div>
                  <Controller
                    control={control}
                    name="fullname"
                    rules={WORD_INPUT_VALIDATION}
                    render={({ field: { value, onChange } }) => (
                      <LMInput
                        label="Full name"
                        placeholder="Your name here"
                        type="text"
                        value={value}
                        onChangeText={onChange}
                        error={errors.fullname && errors.fullname.message}
                      />
                    )}
                  />
                </div>
                <div>
                  <Controller
                    control={control}
                    name="email"
                    rules={EMAIL_INPUT_VALIDATION}
                    render={({ field: { value, onChange } }) => (
                      <LMInput
                        label="Email address"
                        placeholder="Your email address here"
                        type="text"
                        value={value}
                        onChangeText={onChange}
                        error={errors.email && errors.email.message}
                      />
                    )}
                  />
                </div>
                <div>
                  <Controller
                    control={control}
                    name="category"
                    rules={SELECT_INPUT_VALIDATION}
                    render={({ field: { value, onChange } }) => (
                      <AFSelect
                        options={category}
                        label={"What do you want to talk about?"}
                        placeholder="Select category"
                        value={value}
                        onChange={onChange}
                        //@ts-ignore
                        error={errors.category && errors.category.message}
                      />
                    )}
                  />
                </div>

                <div style={{ marginTop: 10 }}>
                  <Controller
                    control={control}
                    name="message"
                    rules={WORD_INPUT_VALIDATION}
                    render={({ field: { value, onChange } }) => (
                      <LMInput
                        label="Your message"
                        placeholder="Write message here"
                        type="textarea"
                        value={value}
                        onChangeText={onChange}
                        error={errors.message && errors.message.message}
                      />
                    )}
                  />
                </div>

                <div style={{ textAlign: "left" }}>
                  <AFButton
                    type="submit"
                    size="large"
                    name="Submit"
                    color="primary"
                    loading={loading}
                    disabled={loading}
                    handleClick={handleSubmit(onSubmit)}
                  />
                </div>
              </div>
            </div>
          </div>
        </Container>
      </section>
      <Container>
        <section>
          <AFPartner />
        </section>
      </Container>
      <section>
        <Footer />
      </section>
    </div>
  );
}

export default Contact;
