import React, { useState } from "react";
import AFText from "./AFText";
import secondBanner from "../assets/images/svg/banner2.svg";
import "../assets/styles/components/input/af_input_style.css";
import { Controller, useForm } from "react-hook-form";
import {
  EMAIL_INPUT_VALIDATION,
  WORD_INPUT_VALIDATION,
} from "../utils/helper/validation";
import LMInput from "./LMInput";
import AFButton from "./AFButton";

function AFPartner() {
  const [loading, setLoading] = useState<boolean>(false);
  let loginInitalValues: any = {
    email: "",
    fullname: "",
  };
  const {
    control,
    getValues,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: loginInitalValues,
  });
  const onSubmit = (data: any) => {};

  return (
    <div className="">
      <div className="row">
        <div className="col-lg-6 col-sm-12 partner_first_col">
          <div id="welcome_to_af_container">
            <AFText text="Partner with Localman" size="h1" />
          </div>

          <div id="journey_container">
            <AFText
              text="We offer food businesses an online service for speedier checkouts at the counter. Send us your contact details and our team will reach out to you as soon as possible."
              size="light"
            />
          </div>

          <div className="row">
            <div style={{ textAlign: "left" }}>
              <Controller
                control={control}
                name="fullname"
                rules={WORD_INPUT_VALIDATION}
                render={({ field: { value, onChange } }) => (
                  <LMInput
                    label="Full name"
                    placeholder="Your name here"
                    type="text"
                    value={value}
                    onChangeText={onChange}
                    error={errors.fullname && errors.fullname.message}
                  />
                )}
              />
            </div>

            <div style={{ textAlign: "left" }}>
              <Controller
                control={control}
                name="email"
                rules={EMAIL_INPUT_VALIDATION}
                render={({ field: { value, onChange } }) => (
                  <LMInput
                    label="Email address"
                    placeholder="Your email address here"
                    type="text"
                    value={value}
                    onChangeText={onChange}
                    error={errors.email && errors.email.message}
                  />
                )}
              />
            </div>

            <div>
              <AFButton
                type="submit"
                size="large"
                name="Submit"
                color="primary"
                loading={loading}
                disabled={loading}
                handleClick={handleSubmit(onSubmit)}
              />
            </div>
          </div>
        </div>

        <div className="col-lg-6 col-sm-12 images_right_container">
          <img id=" banner_image_left" src={secondBanner} className="banner2" />
        </div>
      </div>
    </div>
  );
}
export default AFPartner;
