import React, { useState, CSSProperties, ReactElement } from "react";
import { FaSearch, FaUser } from "react-icons/fa";
import "../assets/styles/components/header/lm_header_style.css";
import logo from "../assets/images/svg/logo.svg";
import { Link } from "react-router-dom";

interface MenuItemProps {
  label: string;
  link: any;
}

const MenuItem: React.FC<MenuItemProps> = ({ label, link }) => {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const menuItemStyle: CSSProperties = {
    margin: "0 10px",
    padding: "10px",
    cursor: "pointer",
    fontSize: "18px",
    color: "rgba(37, 37, 37, 0.50)",
    fontWeight: 500,
    borderBottom: `3px solid ${isHovered ? "#D29A8D" : "transparent"}`,
    transition: "border-color 0.3s ease",
  };

  return (
    <Link to={link}>
      <div
        style={menuItemStyle}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        {label}
      </div>
    </Link>
  );
};

const LMHeader: React.FC = (): ReactElement => {
  return (
    <header className="header">
      <a href="/">
        <img src={logo} alt="Logo" className="logo" />
      </a>

      <div
        style={{
          display: "flex",
        }}
        className="menu_item"
      >
        <MenuItem label="Contact Us" link="/contact" />
        <MenuItem label="About Us" link="/about" />
        <MenuItem label="FAQ" link="/faq" />
        <MenuItem label="Privacy" link="/privacy" />
      </div>
    </header>
  );
};

export default LMHeader;
