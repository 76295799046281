import React from "react";
import "../assets/styles/pages/af_homepage_style.css";
import "../assets/styles/components/input/af_input_style.css";

import { Navbar, Container, Nav } from "react-bootstrap";
import AFText from "../components/AFText";

import LMHeader from "../components/LMheader";
import Footer from "../components/LMFooter";
import LMFAQ from "../components/LMFAQ";
import leafLeft from "../assets/images/svg/left.svg";
import leafRight from "../assets/images/svg/right.svg";

function FAQ() {
  const faqData = [
    {
      id: 1,
      title: "How does LocalMan work?",
      content:
        "LocalMan streamlines the ordering process for food businesses. Simply select your items, proceed to checkout, and experience a fast and secure transaction.",
    },
    {
      id: 2,
      title: "What payment methods are accepted?",
      content:
        "Check out membership page, choose the membership package that is ideal for you. We recommend packages of 1 year validity atleast to get the maximum out of website.",
    },
    {
      id: 3,
      title: "How can I get rewards?",
      content:
        "Check out membership page, choose the membership package that is ideal for you. We recommend packages of 1 year validity atleast to get the maximum out of website.",
    },
    {
      id: 4,
      title: "Is there a transaction fee for using LocalMan?",
      content:
        "Check out membership page, choose the membership package that is ideal for you. We recommend packages of 1 year validity atleast to get the maximum out of website.",
    },
  ];
  return (
    <div>
      <LMHeader />
      <section className="  first_section_container" style={{height: "100%"}}>
        <img
          style={{
            width: 295,
            height: 322.06,
            left: -51,
            top: 10,
            position: "absolute",
            transform: "rotate(-8.43deg)",
            transformOrigin: "0 0",
          }}
          src={leafLeft}
        />
        <img
          style={{
            width: 264.97,
            height: 421,
            right: 0,
            top: 50,
            position: "absolute",
          }}
          src={leafRight}
        />
        <Container>
          <div className="row first_banner_row" style={{ textAlign: "center" }}>
            <div id="welcome_to_af_container">
              <AFText text="Questions? We’ve got answers!" size="h1" />
            </div>

            <div id="">
              <AFText
                text="If you can’t fnd an answer to your question,  send us a message."
                size="light"
                coloredWords={[
                  { word: "send", color: "#F88A32" },
                  { word: "us", color: "#F88A32" },
                  { word: "a", color: "#F88A32" },
                  { word: "message.", color: "#F88A32" },
                ]}
              />
            </div>
          </div>
        </Container>
      </section>
      <Container>
        <section
          style={{ background: "#fff" }}
          className="second_section_container "
        >
          <div className="row">
            {/* <div className="col-lg-3 col-sm-12 ">
            <div className="faq-left"></div>
          </div> */}
            <div style={{ marginTop: "-50px" }}>
              <LMFAQ />
            </div>
          </div>
        </section>
      </Container>
      <section>
        <Footer />
      </section>
    </div>
  );
}

export default FAQ;
