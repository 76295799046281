import React from "react";
import { Row, Col, Container } from "react-bootstrap";
import logo from "../assets/images/svg/logo.svg";
import apple from "../assets/images/svg/apple.svg";
import google from "../assets/images/svg/google.svg";
import phone from "../assets/images/svg/phone.svg";
import email from "../assets/images/svg/email.svg";

const Footer = () => {
  return (
    <Container className="footer ">
      <footer>
        <div className="">
          <Row>
            <Col md={3}>
              <div className="section">
                <img src={logo} alt="Company Logo" />

                <p>
                  Localman is a service that provides seamless food ordering and
                  checkouts as well as secure payment transactions.
                </p>
              </div>
            </Col>

            <Col md={2}>
              <div className="section">
                <h5>Company</h5>

                <p>
                  <a href="/about">About Us</a>
                </p>
                <p>
                  <a href="/faq">FAQ</a>
                </p>
              </div>
            </Col>

            <Col md={2}>
              <div className="section">
                <h5>Store</h5>
                <p>
                  <a href="#">LocalMan Pay</a>
                </p>
                <p>
                  <a href="#">Buy Now PayLater - coming soon</a>
                </p>
                <p>
                  <a href="#">Loan - coming soon</a>
                </p>
              </div>
            </Col>

            <Col md={2}>
              <div className="section">
                <h5>Contact Us</h5>

                <p>
                  <img src={email} alt="email" /> hello@localman.co
                </p>
                <p>
                  <img src={phone} alt="phone" /> +2349095685594
                </p>
              </div>
            </Col>

            <Col md={2}>
              <div className="section">
                <img src={apple} alt="Company Logo" className="logo" />
              </div>
              <div className="section">
                <img src={google} alt="Company Logo" className="logo" />
              </div>
            </Col>
          </Row>
        </div>
        <div>
          <hr></hr>
          <div style={{ textAlign: "center" }}>
            <b style={{ padding: 20 }}>© 2024 LocalMan. All Rights Reserved</b>
          </div>
        </div>
      </footer>
    </Container>
  );
};

export default Footer;
