import React from "react";
import { Form } from "react-bootstrap";

interface SelectOption {
  label: string;
  value: string;
}

interface Props {
  options: SelectOption[];
  label: any;
  value?: string;
  onChange?: any;
  placeholder?: string;
  disabled?: boolean;
  error?: string;
  size?: "small" | "large";
}

const AFSelect: React.FC<Props> = ({
  options,
  value,
  error,
  label,
  onChange,
  placeholder,
  disabled,
  size,
}) => {
  return (
    <Form.Group controlId={label} className="">
      <Form.Label>{label}</Form.Label>
      <Form.Select
        value={value}
        onChange={onChange}
        disabled={disabled}
        className="text_input_style"
        
      >
        {placeholder && <option value="">{placeholder}</option>}
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </Form.Select>
      {error && <Form.Text className="text-danger">{error}</Form.Text>}
    </Form.Group>
  );
};

export default AFSelect;
